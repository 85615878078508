import React, { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import RedCircle from '../../../../../src/assets/Red_Circle_Left.png';
import CustomSelect from '../../pages/add-details/component/CustomSelect';
interface UserInterface {
  _id?: string;
  fullname?: string;
  email?: string;
  password?: string;
  emailVerificationToken?: string;
  isVerified?: boolean;
  name?: string;
  customers?: string;
  account?: string;
  industry?: string;
  preferedTone?: string;
  spacesForFilming?: string;
  hearAboutUs?: string;
  businessSummary?: string;
  userDetailAdded?: boolean;
}
const ContentDvevelopment = () => {
  const [contentDevelopment, setContentDevelopment] = useState({
    createMyContent: '',
    howYouCreateYourContent: ''
  });
  const [userData, setUserData] = useState<UserInterface>({
    fullname: '',
    name: '',
    customers: '',
    account: '',
    industry: '',
    preferedTone: '',
    spacesForFilming: '',
    hearAboutUs: '',
    businessSummary: ''
  });
  const email = localStorage.getItem('UserEmail');
  const navigate = useNavigate();
  const dropDownData = {
    createMyContentOptions: [
      { id: 1, name: 'DIY' },
      { id: 2, name: 'Come to the clue hub' },
      { id: 3, name: 'Book in the clue team' }
    ],
    howYouCreateYourContentOptions: [{ id: 1, name: 'Provide printable list' }]
  };
  const planId = localStorage.getItem('PlanId');
  const storeContentDevelopment = async () => {
    const bodyData = {
      planId: planId,
      contentDevelopment: {
        createMyContent: contentDevelopment.createMyContent,
        howYouCreateYourContent: contentDevelopment.howYouCreateYourContent
      },
      lastStep: 'content-development'
    };
    console.log({ bodyData });
    if (
      !contentDevelopment.createMyContent ||
      !contentDevelopment.howYouCreateYourContent
    ) {
      toast.error('Please provide complete details');
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/plan/update`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyData)
          }
        );
        if (response.ok) {
          const data = await response.json();
          console.log({ data });

          if (data?.code === 200) {
            navigate('/content-plan');
          } else {
            throw new Error(data.status);
          }
        } else {
          throw new Error('Unable to connect to Backend');
        }
      } catch (error: any) {
        toast.error(`${error?.message}`);
        console.log(error.message);
        console.log(error.msg);

        // Handle network errors or exceptions
        console.error('Error:', error);
      }
    }
  };
  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/user/get-profile-data/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        console.log('get-profile-data response', res);
        if (res.ok) {
          const response = await res.json();
          setUserData(response.profileData);
          console.log('***', response);
        }
      })
      .catch((err) => {
        console.log('Error fetching user data', err);
      });
  }, []);

  return (
    <div className="w-full flex flex-col justify-center">
      <div className="lg:flex min-h-screen w-full relative lg:pb-10">
        <div className="flex flex-col w-full items-center px-5 md:px-0">
          <div className="flex flex-col items-center w-full mt-10">
            <p className="text-black md:text-4xl text-3xl font-medium mb-3">
              {'Content development'}
            </p>
            <p className="flex justify-center items-center text-center text-black text-4xl md:text-5xl font-extrabold mb-14">
              {'Create my [....]?'}
            </p>
            <div className="w-full max-w-xl px-10 pt-10 pb-16 space-y-5 border border-cyan-500 rounded-lg bg-white">
              <div>
                <label className="text-black text-base font-normal">
                  {'Create my content'}
                </label>
                <CustomSelect
                  data={dropDownData.createMyContentOptions.map((item) => ({
                    id: item.id,
                    name: item.name
                  }))}
                  placeholder={'Options'}
                  onSelect={(selectedItem: any) => {
                    setContentDevelopment((previous) => ({
                      ...previous,
                      createMyContent: selectedItem.name
                    }));
                    if (selectedItem.name === 'Come to the clue hub') {
                      console.log('@###');
                      window.location.href = `mailto:hello@cluecontent.com?body=I would like to book into film content at the clue hub  here is a link to my content plan.%0D%0AMy details are: %0D%0AName : ${userData.fullname}, %0D%0ACustomers : ${userData.customers}, %0D%0AAccount : ${userData.account},%0D%0AIndustry : ${userData.industry},%0D%0APreferredTone : ${userData.preferedTone},%0D%0ASpaceForFilming : ${userData.spacesForFilming},%0D%0AHearAboutUs : ${userData.hearAboutUs},%0D%0ABusinessSummary : ${userData.businessSummary}&subject=Please can I come to Cambridge to film my content`;
                    }
                    if (selectedItem.name === 'Book in the clue team') {
                      window.location.href = `mailto:hello@cluecontent.com?body=I would like to book into film content  at our place here is a link to my content plan.%0D%0AMy details are:%0D%0AName : ${userData.fullname},%0D%0ACustomers : ${userData.customers},%0D%0AAccount : ${userData.account},%0D%0AIndustry : ${userData.industry},%0D%0APreferredTone : ${userData.preferedTone},%0D%0ASpaceForFilming : ${userData.spacesForFilming},%0D%0AHearAboutUs : ${userData.hearAboutUs},%0D%0ABusinessSummary : ${userData.businessSummary}&subject=Please can you come to me to film my content`;
                    }
                  }}
                />
              </div>
              <div>
                <label className="text-black text-base font-normal">
                  {'How you create your content?'}
                </label>
                <CustomSelect
                  data={dropDownData.howYouCreateYourContentOptions.map(
                    (item) => ({
                      id: item.id,
                      name: item.name
                    })
                  )}
                  placeholder={'Options'}
                  onSelect={(selectedItem: any) => {
                    setContentDevelopment((previous) => ({
                      ...previous,
                      howYouCreateYourContent: selectedItem.name
                    }));
                  }}
                />
              </div>
            </div>
            <div className="flex w-fit justify-center items-center gap-5">
              <button
                className="flex justify-center items-center  mt-10 px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold mb-5"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {'Back'}
              </button>
              <button
                className="flex w-full justify-center items-center px-0 lg:px-10 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg mt-10 text-center text-white text-lg font-bold mb-5"
                onClick={() => {
                  storeContentDevelopment();
                }}
              >
                {'Next'}
              </button>
            </div>
          </div>
        </div>
        <img
          className="hidden md:flex absolute w-[350px] h-[300px] lg:w-[343px] lg:h-[343px] right-0 -rotate-90 bottom-0 -z-10"
          src={RedCircle}
          alt={'details circles'}
        />
        {/* <div className="md:absolute bottom-2 right-10 justify-center md:justify-end items-center gap-4 mb-5 md:mb-0 inline-flex w-full">
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div> */}
      </div>
    </div>
  );
};
export default ContentDvevelopment;
