import { Formik, Form, Field, ErrorMessage } from 'formik';
import React from 'react';

import * as Yup from 'yup';
import { toast } from 'sonner';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setUser } from '../../redux/slices/user.slice';
import { Link } from 'react-router-dom';
import SignInCircles from '../../assets/Sign-In-Circles.png';
import SignInImage from '../../assets/Sign-In-Image.png';
import ClueLogo from '../../assets/Clue-Logo.png';
import GoogleLogin from './google-login';
interface User {
  email: string;
  fullname: string;
}

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialValues = {
    email: '',
    password: ''
  };
  // const email = localStorage.getItem('UserEmail');
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    console.log({ values });

    const { email, password, fullname } = values;
    const userValues: User = {
      email,
      fullname
    };
    const requestBody = {
      email,
      password,
      fullname
    };

    // form is submitted
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/auth/login`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
            // Add any other headers as needed
          },
          body: JSON.stringify(requestBody)
        }
      );
      console.log({ response });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem('LoginToken', data.data.jwt_token);
        localStorage.setItem('UserEmail', email);
        dispatch(setUser({ user: userValues, token: data.jwt_token }));
        if (data?.status === 200) {
          //check if user is verified

          fetch(
            `${process.env.REACT_APP_BACKEND_URI}/user/check-if-user-details-added/${email}`,
            {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json'
              }
            }
          )
            .then(async (res) => {
              console.log('User Add Response', res);
              if (res.ok) {
                const response = await res.json();
                console.log(response.userDetailAdded);
                if (response?.userDetailAdded) {
                  navigate('/createplan');
                } else {
                  navigate('/user-details');
                }
              }
            })
            .catch((err) => {
              console.log('Error adding user details!', err);
            });

          //user added successfully
        } else {
          //an error occured on backend
          toast.error(data.message);
          throw new Error(data.message);
        }
      } else {
        console.log('errr');

        throw new Error('Unable to connect to backend');
      }
    } catch (error: any) {
      toast.error('Incorrect email or password!!');
      console.log(error.message);
      console.log(error.msg);

      // Handle network errors or exceptions
      console.error('Error:', error);
    }
  };
  // useEffect(() => {
  //   fetch(
  //     `${
  //       process.env.REACT_APP_BACKEND_URI
  //     }/user/check-if-user-details-added/${localStorage.getItem('UserEmail')}`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     }
  //   )
  //     .then(async (res) => {
  //       console.log('Check-if-user-details-added Response', res);
  //       if (res.ok) {
  //         const response = await res.json();
  //         console.log(response.userDetailAdded);
  //         if (response?.userDetailAdded && localStorage.getItem('LoginToken')) {
  //           navigate('/todo');
  //         } else if (
  //           !response?.userDetailAdded &&
  //           localStorage.getItem('LoginToken')
  //         ) {
  //           navigate('/user-details');
  //         } else {
  //           navigate('/login');
  //         }
  //       }
  //     })
  //     .catch((err) => {
  //       console.log('Error adding user details!', err);
  //     });
  // }, [email]);
  return (
    <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-3 lg:gap-8 relative">
      <div className="hidden lg:flex h-screen w-full relative">
        <img
          className="absolute w-[643px] h-[643px] left-0 bottom-0"
          src={SignInCircles}
          alt="Circles"
        />
        <img
          className="w-[549px] h-[776px] left-28 top-0 absolute"
          src={SignInImage}
          alt="Sign-In png"
        />
      </div>
      <div className="flex flex-col w-full justify-center md:items-center px-10 md:px-24">
        <div className="flex justify-start w-full">
          <img
            className="max-w-[225px] max-h-[82px] mb-[24px]"
            src={ClueLogo}
            alt="Clue Logo"
          />
        </div>
        <div className="w-full">
          <p className="text-zinc-500 text-base font-medium">Welcome! Back</p>
          <p className="text-black text-5xl font-extrabold mb-[44px]">
            Sign In
          </p>
        </div>
        <GoogleLogin />

        <div className="flex justify-center items-center space-x-2 w-full mb-[35px]">
          <div className="w-10 md:w-20 h-[2px] bg-slate-200"></div>
          <p className="text-base font-medium">Or, sign up with your email</p>
          <div className="w-10 md:w-20 h-[2px] bg-slate-200"></div>
        </div>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="bg-white w-full">
              <div>
                <div className="flex flex-col space-y-1 mb-6 w-full">
                  <label>Email address</label>
                  <Field
                    as="input"
                    placeholder="gilroybworn@gmail.com"
                    className="max-w-full h-[50px] bg-white rounded-lg border border-slate-300 pl-5 outline-1 focus:outline-cyan-500"
                    id="email"
                    name="email"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500"
                  />

                  {/* <input className="max-w-full h-[50px] bg-white rounded-lg border border-slate-300 pl-5" placeholder="gilroybworn@gmail.com" /> */}
                </div>
                <div className="flex flex-col space-y-1 mb-6 w-full">
                  <label>Password</label>
                  <Field
                    as="input"
                    type="password"
                    placeholder="Enter Password"
                    className="max-w-full h-[50px] bg-white rounded-lg border border-slate-300 pl-5 outline-1 focus:outline-cyan-500"
                    id="password"
                    name="password"
                  />
                  <ErrorMessage
                    name="password"
                    component="div"
                    className="text-red-500"
                  />

                  {/* <input className="max-w-full h-[50px] bg-white rounded-lg border border-slate-300 pl-5" placeholder="Enter Password" /> */}
                </div>
                <div className="flex w-full justify-between items-center mb-6">
                  <div className="flex">
                    <input
                      type="checkbox"
                      className="text-cyan-500 bg-yellow-300 focus:ring-0 focus:outline-0 rounded-sm w-4 h-4 mr-2 cursor-pointer"
                    />
                    <p className="w-[141px] text-black text-sm font-medium  leading-tight">
                      Keep me signed in
                    </p>
                  </div>
                  <button
                    className="text-cyan-500 text-sm font-normal  leading-tight"
                    type="button"
                    onClick={() => {
                      navigate('/forgot-password');
                    }}
                  >
                    Forgot Password
                  </button>
                </div>

                <button className="w-full h-14 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-lg font-bold ] mb-6">
                  Sign in
                </button>
              </div>
              <div className="flex w-full items-center  mb-7">
                <p className="w-[161px] text-black text-sm font-medium  leading-tight">
                  Don’t have an account?
                </p>
                <Link
                  to={'/register'}
                  className="text-cyan-500 text-sm font-medium  leading-tight"
                >
                  Create an account now
                </Link>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default Login;
