// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-calendar {
  width: 450px;
  max-width: 100%;
  max-height: 100%;
  background: white;
  border: solid 2px #01C3C3;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.525em;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  .react-calendar__month-view__days__day--weekend {
    color: #d10000;
}

.react-calendar__tile--active {
    background: white;
    border: solid 1px #01C3C3;
    border-radius: 10px;
    color: #01C3C3;
}
button {
    margin: 0;
    border: 0;
    outline: none;
    color: black;
    border-radius: 10px;
    flex-basis: 4.2857%;
}
button:hover {
    color: blue;
    background: #01C3C3;
}
.calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
    background: #01C3C3;
    color: white;
}
.calendar__tile--active:enabled{
    background: #d10000;
}
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/pages/strategic-date/calendarStyles.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,yBAAyB;EACzB,yCAAyC;EACzC,oBAAoB;EACpB,8EAA8E;EAC9E;IACE,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;AAClB;AACA;IACI,SAAS;IACT,SAAS;IACT,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,YAAY;AAChB;AACA;IACI,mBAAmB;AACvB;AACA","sourcesContent":[".react-calendar {\n  width: 450px;\n  max-width: 100%;\n  max-height: 100%;\n  background: white;\n  border: solid 2px #01C3C3;\n  font-family: Arial, Helvetica, sans-serif;\n  line-height: 1.525em;\n  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);\n  .react-calendar__month-view__days__day--weekend {\n    color: #d10000;\n}\n\n.react-calendar__tile--active {\n    background: white;\n    border: solid 1px #01C3C3;\n    border-radius: 10px;\n    color: #01C3C3;\n}\nbutton {\n    margin: 0;\n    border: 0;\n    outline: none;\n    color: black;\n    border-radius: 10px;\n    flex-basis: 4.2857%;\n}\nbutton:hover {\n    color: blue;\n    background: #01C3C3;\n}\n.calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {\n    background: #01C3C3;\n    color: white;\n}\n.calendar__tile--active:enabled{\n    background: #d10000;\n}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
