import React, { useState } from 'react';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import RedCircle from '../../../../assets/Red_Circle_Left.png';
import './calendarStyles.css';
import AwarenessScreenPopupIcon from './component';
import { InformationCircleIcon, PlayIcon } from '@heroicons/react/24/outline';
import CustomSelect from '../add-details/component/CustomSelect';
import InfoPopup from './component/info-popup';
const StrategicCalendar = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isPopupVisible, setPopupVisible] = useState(false);
  const navigate = useNavigate();
  const [showAwarenessScreenPopup, setAwarenessScreenPopup] = useState(false);
  const planId = localStorage.getItem('PlanId');
  const [event, setEvent] = useState('none');
  const [eventDetails, setEventDetails] = useState('');
  const dropDownData = {
    events: [
      { id: 1, name: 'Sales Event' },
      { id: 2, name: 'Key Event' }
    ]
  };
  const currentYear = new Date().getFullYear();
  const addStrategicDate = async () => {
    if (!eventDetails) {
      toast.error('Please select inputted date!');
    } else {
      const bodyData = {
        planId: planId,
        strategicDate: selectedDate,
        event: { event: event, eventDetails: eventDetails },
        lastStep: 'input-strategic-date'
      };
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/plan/update`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyData)
          }
        );
        if (response.ok) {
          const data = await response.json();
          console.log({ data });

          if (data?.code === 200) {
            setAwarenessScreenPopup(true);
          } else {
            throw new Error(data.status);
          }
        } else {
          throw new Error('Unable to connect to Backend');
        }
      } catch (error: any) {
        toast.error(`${error?.message}`);
        console.log(error.message);
        console.log(error.msg);

        // Handle network errors or exceptions
        console.error('Error:', error);
      }
    }
  };
  const openPopup = (e: MouseEvent) => {
    console.log('opn popup called');
    e.stopPropagation();
    setPopupVisible(true);
  };

  const closePopup = () => {
    console.log('Close popup called');
    setPopupVisible(false);
  };
  return (
    <div className="w-full flex flex-col justify-center">
      <div className="lg:flex min-h-screen w-full relative lg:pb-10">
        <div className="flex flex-col w-full items-center px-5 md:px-0">
          <div className="flex flex-col items-center w-full mt-10">
            <div className="text-black text-2xl md:text-4xl font-medium ">
              What's coming up?
            </div>
            <div className="flex justify-center items-start">
              <p className="flex text-center text-black text-3xl md:text-5xl font-bold md:mb-8 mb-4 mt-5">
                {'Which Best Suits You?'}
              </p>
              <InformationCircleIcon
                onClick={(e: any) => {
                  openPopup(e);
                  console.log('first');
                }}
                className="w-10 h-10 text-teal-500 mt-3 ml-4 cursor-pointer"
                title="Tell us what your company is up to in the next month. Select a date in the calendar and input some information for us to create your strategic content plan. There are two options to choose from, a key date or a selling day. Key dates can be days such as business birthdays, exhibitions, a website launch etc. Selling days are days when you are specifically selling I.e launching a new collection, have an offer on your services, want to dedicate a day to talking about your offering. At this point it’s important not to select every day but just to input a handful of key moments in your diary that you want your content to reflect."
              />
            </div>
            {isPopupVisible && (
              <InfoPopup
                onClose={closePopup}
                icon={''}
                description="Tell us what your company is up to in the next month. Select a date in the calendar and input some information for us to create your strategic content plan. There are two options to choose from, a key date or a selling day. Key dates can be days such as business birthdays, exhibitions, a website launch etc. Selling days are days when you are specifically selling I.e launching a new collection, have an offer on your services, want to dedicate a day to talking about your offering. At this point it’s important not to select every day but just to input a handful of key moments in your diary that you want your content to reflect."
              />
            )}
            <div className="flex flex-col w-full max-w-md space-y-10 mt-3 mb-8">
              <button
                className="flex justify-center items-center w-full py-3 bg-rose-500 hover:bg-rose-400 rounded-lg text-center text-white hover:text-white text-lg font-bold"
                onClick={() => {
                  navigate('/play-video');
                }}
              >
                <PlayIcon className="w-6 h-6 text-white fill-white mr-2" />
                <p>{'Watch explanation video'}</p>
              </button>
            </div>
            <div className="relative flex-col w-fit">
              <Calendar
                // selectRange
                // allowPartialRange
                // showNeighboringMonth
                minDate={new Date()} // January 1st of the current year
                maxDate={new Date(currentYear, 11, 31)}
                onChange={(value, event) => {
                  setSelectedDate(value as Date);
                  console.log('', value?.valueOf());
                }}
                value={selectedDate}
                className="react-calendar  rounded-2xl m-5"
              />
            </div>
            {!showAwarenessScreenPopup ? '' : <AwarenessScreenPopupIcon />}
          </div>
          <div className="flex flex-col w-full max-w-md space-y-2">
            <CustomSelect
              data={dropDownData.events.map((item) => ({
                id: item.id,
                name: item.name
              }))}
              placeholder={'Inputed date'}
              onSelect={(selectedItem: any) => {
                console.log('event', selectedItem);
                setEvent(selectedItem.name);
              }}
            />
            {event === 'Key Event' && (
              <div className=" space-y- mb-">
                <label className="test-sm font-normal">
                  Tell us more about what’s happening on this day?
                </label>
                <input
                  className="w-full px-5 relative h-14 bg-white rounded-lg border border-teal-500 mb-5 focus:outline-1 focus:outline-teal-500"
                  placeholder="Type your answer here...."
                  onChange={(e) => {
                    setEventDetails(e.target.value);
                  }}
                />
              </div>
            )}
            {event === 'Sales Event' && (
              <div className=" mb-10">
                <label className="test-sm font-normal">
                  What are you selling today?
                </label>
                <input
                  className="w-full px-5 relative h-14 bg-white rounded-lg border border-teal-500 mb-5 focus:outline-1 focus:outline-teal-500"
                  placeholder="Type your answer here...."
                  onChange={(e) => {
                    setEventDetails(e.target.value);
                  }}
                />
              </div>
            )}

            <div className="flex gap-5 items-center">
              {' '}
              {/* Adjusted alignment */}
              <button
                className="flex justify-center items-center w-44 px-4 lg:px-16 py-3 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {'Back'}
              </button>
              <button
                className="w-64 py-4 bg-rose-500 hover:bg-rose-400 rounded-lg text-center text-white hover:text-white text-lg font-bold"
                onClick={() => {
                  addStrategicDate();
                }}
              >
                {'Add more details'}
              </button>
            </div>
          </div>
        </div>
        <img
          className="hidden md:flex absolute w-[360px] h-[350px] lg:w-[380px] lg:h-[380px] right-0 -rotate-90 bottom-0 -z-10"
          src={RedCircle}
          alt={'details circles'}
        />
        {/* <div className="md:absolute bottom-2 right-10 justify-center md:justify-end items-center gap-4 my-5 md:my-0 inline-flex w-full">
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div> */}
      </div>
    </div>
  );
};
export default StrategicCalendar;
