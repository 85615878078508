import React, { useRef, useEffect } from 'react';
interface PopupProps {
  onClose: () => void;
  icon?: string;
  heading?: string;
  description?: string;
}
const InfoPopup: React.FC<PopupProps> = ({
  onClose,
  icon,
  heading,
  description
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      console.log('click outside');
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onClose]);
  return (
    <div
      ref={popupRef}
      className=" flex flex-col max-w-sm  px-5 rounded-lg gap-4 absolute bg-gray-200 z-50 justify-center items-center top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 shadow-xl  border cursor-pointer"
    >
      <div className="flex flex-col items-center justify-center mt-5">
        <p className="flex items-center justify-center text-center text-sm mb-5">
          {description}
        </p>
      </div>
    </div>
  );
};

export default InfoPopup;
