import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useDispatch, UseDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import FacebookLogin from '@greatsumini/react-facebook-login';
import SignUpCircles from '../../../../assets/Sign-In-Circles.png';
import ClueLogo from '../../../../assets//Clue-Logo.png';
import { setUser } from '../../../../redux/slices/user.slice';
import { setInstagramUserData } from '../../../../redux/slices/instagram.data.slice';
import DaysLoader from '../day-of-awareness/component/days-loader';
function EditProfile() {
  // let userEmail = localStorage.getItem('UserEmail');
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [userName, setUserName] = useState();
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState('');
  const [email, setEmail] = useState(localStorage.getItem('UserEmail'));
  const [instaUsername, setInstaUsername] = useState('');
  const [password, setPassword] = useState({
    password: '',
    confirmNewPassword: ''
  });
  const [isInstagramConnected, setInstagramConnected] = useState(false);
  const [instaConnectionLoader, seInstaConnectionLoader] = useState(false);
  const handleConnectInsta = async () => {
    seInstaConnectionLoader(true);
    const url = `https://instagram-data1.p.rapidapi.com/user/feed/v2?username=${instaUsername}`;
    // const url = `https://instagram-scraper-api2.p.rapidapi.com/v1.2/posts?username_or_id_or_url=mrbeast`;

    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': 'c2e86b0460mshe1146136755bf0ep1b3b42jsnbfb1f7d5b9f9',
        'x-rapidapi-host': 'instagram-data1.p.rapidapi.com'
      }
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();

      if (result.items) {
        localStorage.setItem(
          'InstagramUserPosts',
          JSON.stringify(result.items)
        );
        dispatch(setInstagramUserData({ items: result.items }));
        const bodyData = {
          email: email,
          instaUserName: instaUsername
        };
        fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        })
          .then(async (res) => {
            if (res.ok) {
              setInstagramConnected(true);
              seInstaConnectionLoader(false);
            }

            console.log('save insta username response', res);
          })
          .catch((err) => {
            console.log('Error updating password', err);
          });

        // setConnectionLoader(false);
      } else {
        toast.error(
          'Unable to connect please check you instagram username is correct!!'
        );
        // setConnectionLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log('chcking connection');
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/user/get-profile-data/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        if (res.ok) {
          const response = await res.json();
          console.log('fetch profile data response', response);
          setUserName(response.profileData.fullname);
          setName(response.profileData.fullname);
          setUserEmail(response.profileData.email);
          setInstaUsername(response.profileData?.instaUserName);
          console.log('dasfsaf', response.profileData?.instaUserName);
          if (response.profileData?.instaUserName) {
            setInstagramConnected(true);
          } else setInstagramConnected(false);
        }
      })
      .catch((err) => {
        console.log('Error fetching profile data', err);
      });
    // fetch selected days of awareness
  }, []);
  const updateProfileData = () => {
    //below code is to add functionality to change even single field previously it wasn't allowed
    const changedData: any = {};
    if (name !== userName) changedData.fullname = userName;
    if (email !== userEmail) {
      if (email) {
        changedData.updatedEmail = userEmail;
        localStorage.setItem('UserEmail', userEmail);
        setEmail(email);
      }
    }
    if (
      password.password !== '' &&
      password.confirmNewPassword !== '' &&
      password.password === password.confirmNewPassword
    ) {
      changedData.password = password;
    } else if (password.password !== password.confirmNewPassword) {
      toast.error("Passwords doesn't matched!");
    }
    if (
      changedData.password ||
      changedData.fullname ||
      changedData.updatedEmail
    ) {
      const bodyData = {
        email: email,
        ...changedData
      };

      fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData)
      })
        .then(async (res) => {
          const respData = await res.json();
          console.log('update-profile-data', respData);
          if (res.ok) {
            toast.message('Profile updated');
            const userData: any = {
              fullname: changedData.fullname,
              email: email
            };
            console.log('disptching with', userData);
            dispatch(setUser({ user: userData, token: '' }));
          } else {
            toast.error('Error updating profile');
          }
        })
        .catch((err) => {
          console.log('Error fetching days of awareness', err);
        });
    }
  };
  const disconnectInsta = () => {
    const bodyData = {
      email: email,
      instaUserName: ''
    };
    fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })
      .then(async (res) => {
        const respData = await res.json();
        console.log('update-profile-data', respData);
        if (res.ok) {
          toast.message('Instagram disconnected,');
          setInstaUsername('');
          setInstagramConnected(false);
        } else {
          toast.error('Unable to disconnect instagram');
        }
      })
      .catch((err) => {
        console.log('Disconnecting instagram error', err);
      });
  };
  return (
    <div className="relative w-full justify-center items-center min-h-screen flex flex-col ">
      <img
        className="absolute w-[400px] h-[393px] left-0 bottom-0 -z-10"
        src={SignUpCircles}
        alt={'details circles'}
      />
      <div className="flex flex-col w-full max-w-2xl items-center px-10 md:px-24">
        <div className="flex flex-col items-center w-full mt-10">
          <img
            className="max-w-[225px] max-h-[82px] mb-[40px]"
            src={ClueLogo}
            alt={'Clue Logo'}
          />
          <p className="text-black text-4xl font-medium">Profile</p>
          <p className="text-black text-5xl font-extrabold mb-[54px]">
            {'Edit your Profile?'}
          </p>
        </div>
        <div className="w-full flex flex-col space-y-4">
          <div className="relative space-y-2">
            <label className="text-sm font-normal">Name</label>
            <input
              className="w-full pl-5 pr-10 relative h-14 bg-white rounded-lg border border-teal-500 mb-5 focus:outline-1 focus:outline-teal-500"
              placeholder="Qorem ipsum dolor sit "
              value={userName}
              onChange={(e: any) => {
                setUserName(e.target.value);
              }}
            />
          </div>
          <div className="relative space-y-2">
            <label className="text-sm font-normal">Email Address</label>
            <input
              className="w-full px-5 relative h-14 bg-white rounded-lg border border-teal-500 mb-5 focus:outline-1 focus:outline-teal-500 text-gray-500"
              placeholder="Enter email"
              value={userEmail}
              onChange={(e: any) => {
                setUserEmail(e.target.value);
              }}
              disabled={true}
            />
          </div>
          <div className=" space-y-2">
            <label className="text-sm font-normal">Change Password</label>
            <input
              className="w-full px-5 relative h-14 bg-white rounded-lg border border-teal-500 mb-5 focus:outline-1 focus:outline-teal-500"
              placeholder="Enter new password"
              value={password.password}
              onChange={(e: any) => {
                setPassword((prev) => ({ ...prev, password: e.target.value }));
              }}
              type={'password'}
            />
          </div>
          <div className=" space-y-2">
            <label className="text-sm font-normal">Confirm New Password</label>
            <input
              className="w-full px-5 relative h-14 bg-white rounded-lg border border-teal-500 mb-5 focus:outline-1 focus:outline-teal-500"
              placeholder="Confirm password"
              value={password.confirmNewPassword}
              onChange={(e: any) => {
                setPassword((prev) => ({
                  ...prev,
                  confirmNewPassword: e.target.value
                }));
              }}
              type={'password'}
            />
          </div>
          <div className="flex justify-between items-end space-x-5">
            {isInstagramConnected ? (
              <>
                <button className="w-full h-14 bg-green-500 hover:bg-green-400 rounded-lg text-white text-center  text-sm font-medium">
                  Connected as {instaUsername}
                </button>
              </>
            ) : (
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleConnectInsta();
                }}
                className="flex w-full flex-col justify-center items-center text-center"
              >
                <input
                  className="w-full px-5 relative h-14 bg-white rounded-lg border border-slate-300 mb-5 focus:outline-1 focus:outline-cyan-500"
                  placeholder="instagram username"
                  value={instaUsername}
                  required
                  onChange={(e: any) => {
                    setInstaUsername(e.target.value);
                  }}
                />
                {instaConnectionLoader && <DaysLoader />}
                <button
                  className="w-full h-14 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-4"
                  type="submit"
                >
                  Connect
                </button>
              </form>
            )}
            {isInstagramConnected && (
              <button
                className="w-full h-14  bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-5"
                onClick={() => {
                  disconnectInsta();
                }}
              >
                Disconnect
              </button>
            )}
            {/* <div className="">
              <label className="text-sm font-normal">Instagram</label>
              <input
                className="w-full px-5 relative h-14 bg-white rounded-lg border border-teal-500 focus:outline-1 focus:outline-teal-500"
                placeholder="Username"
                value={instaUsername}
                disabled={true}
              />
            </div> */}
            {/* {isInstagramConnected ? (
              <button
                className="w-full h-14  bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium"
                onClick={() => {
                  localStorage.removeItem('InstagramAccessToken');
                  setInstagramConnected(false);
                  // setInstaUsername('');
                }}
              >
                Disconnect
              </button>
            ) : (
              <FacebookLogin
                appId="1490556441489744"
                children={<div className="font-bold">Connect</div>}
                className="w-full h-14 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium"
                onSuccess={(response) => {
                  console.log('Login Success!', response);
                  console.log('**first', response.accessToken);
                  // get long lived token
                  fetch(
                    `${process.env.REACT_APP_BACKEND_URI}/auth/get-facebook-long-lived-token/${response.accessToken}`,
                    {
                      method: 'GET',
                      headers: {
                        'Content-Type': 'application/json'
                      }
                    }
                  )
                    .then(async (response) => {
                      const data = await response.json();
                      // store insta token in local storage
                      localStorage.setItem(
                        'InstagramAccessToken',
                        data.longLivedToken
                      );
                      // to receive the instagram posts we would need facebook page id and instagram connected to that page instgram account id
                      //store meta ids to later fetch posts
                      localStorage.setItem('InstagramUsername', data.username);
                      localStorage.setItem('FacebookPageID', data.pageId);
                      localStorage.setItem(
                        'InstagramUserID',
                        data.instagramAccountId
                      );

                      setInstagramConnected(true);
                      // setInstaUsername(data.username);
                    })
                    .catch((error) => {
                      console.log('Error fetching long lived token', error);
                    });
                }}
                onFail={(error) => {
                  console.log('Login Failed!', error);
                }}
                onProfileSuccess={(response) => {
                  console.log('Get Profile Success!', response);
                }}
              ></FacebookLogin>
            )} */}
          </div>
        </div>
        <div className="flex w-full justify-center items-center gap-5">
          <button
            className="flex justify-center items-center  my-8  px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold"
            onClick={() => {
              navigate(-1);
            }}
          >
            {'Back'}
          </button>
          <button
            className="w-full px-5 relative h-14 bg-rose-500 hover:bg-rose-400 rounded-lg my-8 text-center text-white text-lg font-bold"
            onClick={() => {
              updateProfileData();
            }}
          >
            {'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
