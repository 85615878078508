import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import YellowCircle from '../../../../assets/Yellow-Circle-Left.png';
import {
  ArrowUpIcon,
  ArrowDownIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/solid';
import { toast } from 'sonner';
import Loader from '../day-of-awareness/component/days-loader';
import { containsOnlySpaces } from '../../../../utils';
import CustomSelect from '../../../home/pages/add-details/component/CustomSelect';
import InstagramIcon from '../../../../../src/assets/SVG/Instagram-Icon.svg';
import FacebookIcon from '../../../../../src/assets/SVG/Facebook-Icon.svg';
import LinkedinIcon from '../../../../../src/assets/SVG/LinkedIn-Icon.svg';
import SplineChart from './apex-charts/SplineAreaChart';
import StackedLineChart from './apex-charts/StackedLineChart';
import { getScore } from './component/getScore';
import AddIndustry from './component/addIndustry';
import { getDate } from './component/getDate';
import MultiSelectDropdown from './component/dropdown/industryDropdown';
import {
  IndustryData,
  IndustrySeriesData,
  OptionType,
  TabsDataInteface
} from './types';
import { getEngagementRate } from './component/getEngagementRates/getEngagementRates';

const platforms = [
  {
    name: 'Instagram',
    image: InstagramIcon,
    description: `1 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'Facebook',
    image: FacebookIcon,
    description: `2 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'Linkedin',
    image: LinkedinIcon,
    description: `3 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  }
  // More people...
];
const tabs = [
  {
    name: 'Analysis',
    description: `1 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'Improvement',
    description: `2 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'Prediction',
    description: `3 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'Rating',
    description: `4 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  }
];

type Platform = 'Instagram' | 'Facebook' | 'Linkedin';

interface ConnectedPlatforms {
  Instagram: boolean;
  Facebook: boolean;
  Linkedin: boolean;
}

const AnalyzeData = () => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [showSearchLoader, setSearchShowLoader] = useState(false);
  const [suggestion, setSuggestion] = useState();
  const [showSuggestionLoader, setShowSuggestionLoader] = useState(true);
  const [engagementRateData, setEngagementRateData] = useState<any>([]);
  const [overAllScoreData, setOverAllScoreData] = useState<any>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<OptionType[]>([]);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [searchText, setSearchText] = useState('');
  const [industryPosts, setIndustryPosts] = useState<any>([{}]);
  const instagramID = localStorage.getItem('InstagramUserID');
  const instaToken = localStorage.getItem('InstagramAccessToken');
  const email = localStorage.getItem('UserEmail');
  const instaUsername = localStorage.getItem('InstagramUsername');
  const [currentTab, setCurrentTab] = useState(0);
  const [currentPlatform, setCurrentPlatform] = useState(0);
  const [industrySeriesData, setIndustrySeriesData] = useState<
    IndustrySeriesData[]
  >([{ name: '', data: [] }]);
  const [userPosts, setUserPosts] = useState([]);
  const [tabsData, setTabsData] = useState<TabsDataInteface[]>([
    { name: 'Analysis', text: '' },
    { name: 'Suggestions', text: '' },
    { name: 'Predictions', text: '' },
    { name: 'Ratings', text: '' }
  ]);
  const [percentageData, setPercentageData] = useState<any>();

  const [connectedPlatforms, setConnectedPlatforms] = useState({
    // Instagram: localStorage.getItem('instaConnected') === 'true',
    Instagram: true,
    Facebook: false,
    Linkedin: false
  });

  const isPlatformConnected = (platformIndex: number) => {
    const platformName = platforms[platformIndex].name;
    return connectedPlatforms[platformName as Platform];
  };

  const handleConnect = (platform: Platform) => {
    setConnectedPlatforms((prevState) => ({
      ...prevState,
      [platform]: true
    }));
  };

  const handleSelectPlatforms = (index: any) => {
    setCurrentPlatform(index);
  };

  const handleSelectTab = (index: any) => {
    setCurrentTab(index);
  };
  // useEffect(() => {
  //   console.log('fethcing scores');
  //   const body = {
  //     post: currentPost
  //   };
  //   fetch(
  //     `${process.env.REACT_APP_BACKEND_URI}/user/get-scores`,
  //     // `${process.env.REACT_APP_BACKEND_URI}/user/analyze-posts-data/${instaToken}/${instagramID}/${email}`,
  //     {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify(body)
  //     }
  //   )
  //     .then(async (res) => {
  //       // if (res.ok) {
  //       const data = await res.json();
  //       console.log('score response', data);
  //       setScore(data.score.scores);
  //       setPercentageData(getScore(data.score.scores));
  //       setShowLoader(false);
  //       // }
  //     })
  //     .catch((err) => {
  //       toast.error('Please make sure you have connected Instagram Account');
  //       setShowLoader(false);
  //       console.log('Error fetching scores', err);
  //     });
  // }, []);

  useEffect(() => {
    if (rating < 30) {
      setFeedback('We can fix that ');
    } else if (rating <= 50) {
      setFeedback("You 're on the right track ");
    } else if (rating > 50) {
      setFeedback('Yay! Well done!');
    }
    console.log('rating', rating);
  }, [rating]);

  const handleSearch = () => {
    console.log('srch txt', searchText);
    setIndustryPosts([]);
    setSearchShowLoader(true);
    // fetch search post
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/user/search-analyze-my-data/${instaToken}/${instagramID}/${searchText}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        console.log('search fetch response', res);
        if (res.ok) {
          const response = await res.json();
          console.log(response);
          setIndustryPosts(response.industryPostsData);
          setSearchShowLoader(false);
        }
      })
      .catch((err) => {
        toast.error('Please make sure you have connected Instagram Account');
        setShowLoader(false);
        console.log('Error fetching analysis details', err);
      });
  };

  const [industryDropDownData, setIndustryDropDownData] = useState<
    OptionType[]
  >([]);

  useEffect(() => {
    const industryNames = localStorage.getItem('IndustryNames') || '';
    const industryNamesArray =
      industryNames.length > 0 ? JSON.parse(industryNames) : [];
    const finalisedArray: any = [];
    industryNamesArray.map((industry: any) => {
      if (!finalisedArray.includes(industry.name))
        finalisedArray.push({ value: industry.name, label: industry.name });
    });

    setIndustryDropDownData((prev: any) => [...prev, ...finalisedArray]);
    //extract user posts
    const userData = localStorage.getItem('InstagramUserPosts');
    const data1 = userData ? JSON.parse(userData) : [];
    const userPostData = data1.map((post: any) => {
      return {
        caption: post.caption.text,
        like_count: post.like_count,
        comment_count: post.comment_count
      };
    });
    setUserPosts(userPostData);
    setEngagementRateData(getEngagementRate(data1));
    console.log('setEngagementRateData', engagementRateData);
  }, []);
  useEffect(() => {
    setRating(
      engagementRateData[engagementRateData.length - 1] -
        engagementRateData[engagementRateData.length - 2]
    );
  }, [engagementRateData]);
  // data creation for industry graph
  useEffect(() => {
    setIndustrySeriesData([{ name: 'UserData', data: engagementRateData }]);

    const industryData = localStorage.getItem('IndustryData');
    const data = industryData ? JSON.parse(industryData) : [];

    console.log('insrer', industrySeriesData);
    //gete selected industries
    const selectedIndustryNames = selectedIndustry.map(
      (industry) => industry.value
    );
    console.log('selectedIndustryNames', selectedIndustryNames);
    const selectedIndustryData = data.filter((industry: any) =>
      selectedIndustryNames.includes(industry.industry)
    );
    selectedIndustryData.map((ind: IndustryData) => {
      setIndustrySeriesData((prev) => [
        ...prev,
        {
          name: ind.industry,
          data: getEngagementRate(ind.data)
        }
      ]);
    });
  }, [selectedIndustry]);
  useEffect(() => {
    const bodyData = { userPosts: userPosts };
    fetch(`${process.env.REACT_APP_BACKEND_URI}/user/analyze-user-posts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })
      .then(async (res) => {
        if (res.ok) {
          const response = await res.json();
          const analysis = response.analysis;
          setTabsData(analysis);
          setShowSuggestionLoader(false);
        }
      })
      .catch((err) => {
        toast.error('Please make sure you have connected Instagram Account');
        console.log('Error fetching analysis details', err);
        setShowSuggestionLoader(false);
      });
  }, [userPosts]);
  return (
    <div className="flex flex-col h-full relative lg:pb-10 items-center">
      <div className="flex flex-col items-center w-full mt-10">
        <p className="flex justify-center items-center text-center text-black text-4xl md:text-5xl font-extrabold mb-8">
          {'Analyse my data'}
        </p>
      </div>
      <div className="w-full justify-start px-10 mb-10">
        <p className="text-black text-2xl md:text-2xl font-medium">
          {'Connect Platform'}
        </p>

        <div>
          <div className="sm:hidden">
            <label htmlFor="platforms" className="sr-only">
              Select a Platrom
            </label>
            <select
              id="platforms"
              name="platforms"
              value={currentPlatform}
              onChange={(e) => handleSelectPlatforms(parseInt(e.target.value))}
              className="block w-full rounded-md border-rose-500 py-2 pl-3 pr-10 text-base focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
            >
              {platforms?.map((platform, index) => (
                <option key={platform.name} value={index}>
                  {platform.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="flex w-full justify-start items-center border-gray-200 mt-4">
              <nav
                className="flex w-full border border-teal-500 rounded-r-lg rounded-l-lg"
                aria-label="Tabs"
              >
                {platforms?.map((platform, index) => (
                  <button
                    key={platform.name}
                    onClick={() => handleSelectPlatforms(index)}
                    className={`${
                      currentPlatform === index
                        ? 'border-rose-500 bg-rose-500 text-white'
                        : 'text-gray-900 hover:text-gray-900 hover:bg-rose-100'
                    } ${
                      index === 2
                        ? 'rounded-r-lg'
                        : index === 0
                          ? 'rounded-l-lg'
                          : ''
                    } flex w-full items-center justify-center whitespace-nowrap border-teal-500 border-l py-2 px-6 text-sm font-medium`}
                    aria-current={
                      currentPlatform === index ? 'page' : undefined
                    }
                  >
                    <img
                      className="flex w-6 h-6 mr-5"
                      src={platform.image}
                      alt={'details circles'}
                    />
                    <span>{platform.name}</span>
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 w-full mt-3">
          {platforms?.map((platform, index) =>
            currentPlatform === index &&
            !connectedPlatforms[platform.name as Platform] ? (
              <div
                key={platform.name}
                className={`flex flex-col items-center justify-center border border-teal-500 rounded-lg w-full py-10 px-5`}
              >
                <img
                  className="flex w-16 h-16 mr-5 mb-5"
                  src={platform.image}
                  alt={'details circles'}
                />
                <p className="font-semibold">{`Connect your ${platform.name}`}</p>
                <p>Click on Connect Button</p>
                <button
                  className="mt-5 bg-rose-500 text-white w-full max-w-[250px] rounded-md px-12 h-14 text-sm hover:bg-rose-400"
                  onClick={() => handleConnect(platform.name as Platform)}
                >
                  connect
                </button>
              </div>
            ) : null
          )}
        </div>
      </div>
      <div
        className={`w-full justify-start px-10 mb-10 ${isPlatformConnected(currentPlatform) ? '' : 'hidden'}`}
      >
        <p className="text-black text-2xl md:text-2xl font-medium">
          {'Report and analysis'}
        </p>
        <div className="flex flex-col md:flex-row w-full justify-between items-center md:space-x-6 z-10">
          <div className="w-full max-w-5xl">
            <MultiSelectDropdown
              industryDropDownData={industryDropDownData}
              selectedIndustry={selectedIndustry}
              setSelectedIndustry={setSelectedIndustry}
            />
          </div>
          <button className="bg-rose-500 text-white w-full max-w-[250px] rounded-md md:px-5 lg:px-12 h-14 text-sm hover:bg-rose-400 mb-2">
            Update
          </button>
          <AddIndustry setIndustryDropDownData={setIndustryDropDownData} />
        </div>
      </div>
      {showLoader ? (
        <Loader screen="analyze-my-data" />
      ) : (
        <div
          className={`w-full px-10 ${isPlatformConnected(currentPlatform) ? '' : 'hidden'}`}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 w-full z-50 gap-8 mb-8">
            <div className="col-span-3 flex flex-col justify-center items-center shadow-xl p-5 rounded-lg bg-white border border-teal-400">
              <div className=" flex justify-between items-center w-full mb-6">
                <p className="text-black text-[22px] font-bold">
                  {instaUsername}
                </p>
                {rating < 50 ? (
                  <div className="flex bg-red-100 rounded-lg text-red-800 px-1.5 py-1 text-sm justify-center items-center space-x-1">
                    <ArrowDownIcon className="text-red-500 w-3 h-3" />
                    <p>{rating + '%'}</p>
                  </div>
                ) : (
                  <div className="flex bg-green-100 rounded-lg text-green-800 px-1.5 py-1 text-sm justify-center items-center space-x-1">
                    <ArrowUpIcon className="text-green-500 w-3 h-3" />
                    <p>{rating + '%'}</p>
                  </div>
                )}
              </div>
              <p className="w-full text-black text-sm font-normal">
                {feedback}
              </p>
            </div>
            <div className="col-span-9 p-5 rounded-lg border border-teal-500">
              <div className="w-full flex justify-end space-x-5">
                <button className="border border-teal-500 max-w-[100px] w-full rounded-md">
                  All
                </button>
                <button className="border border-teal-500 max-w-[100px] w-full rounded-md">
                  April ,2024
                </button>
              </div>
              <SplineChart
                engagementRateData={engagementRateData}
                overAllScoreData={overAllScoreData}
              />
            </div>
          </div>
          <div className="w-full max-w-screen p-5 my-10 border border-teal-500 rounded-lg">
            <div className="w-full flex justify-end space-x-5">
              <button className="border border-teal-500 max-w-fit px-3 w-full rounded-md">
                All social Channels
              </button>
              <button className="border border-teal-500 max-w-[100px] w-full rounded-md">
                April ,2024
              </button>
            </div>
            <div className="">
              <StackedLineChart industryDataSeries={industrySeriesData} />
            </div>
          </div>
          <div className="flex flex-col w-full mb-10">
            <p className="mb-2 text-[24px] font-bold">Suggestion</p>
            {/* <textarea
              className="relative resize-none w-full max-w-full px-[25px] h-[200px] pt-5 pb-[15px] bg-teal-50 rounded-2xl border border-teal-400 flex-col justify-end items-end  gap-[13px] inline-flex mb-5 focus:outline-1 focus:outline-teal-400 focus:border-teal-500 focus:ring-1 focus:ring-teal-500"
              placeholder={"what didn't land well"}
              defaultValue={improvements}
            /> */}
            <div className="">
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="tabs"
                    name="tabs"
                    value={currentTab}
                    onChange={(e) => handleSelectTab(parseInt(e.target.value))}
                    className="block w-full rounded-md border-rose-500 py-2 pl-3 pr-10 text-base focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
                  >
                    {tabsData?.map((tab: TabsDataInteface, index) => (
                      <option key={tab.name} value={index}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="flex w-full justify-start items-center border-gray-200">
                    <nav
                      className="flex border border-teal-500 rounded-tr-lg rounded-tl-lg"
                      aria-label="Tabs"
                    >
                      {tabsData?.map((tab: TabsDataInteface, index) => (
                        <button
                          key={tab.name}
                          onClick={() => handleSelectTab(index)}
                          className={`${
                            currentTab === index
                              ? 'border-rose-500 bg-rose-500 text-white'
                              : 'text-rose-500 hover:text-white hover:bg-rose-500'
                          } ${
                            index === 3
                              ? 'rounded-tr-lg'
                              : index === 0
                                ? 'rounded-tl-lg'
                                : ''
                          } whitespace-nowrap border-teal-500 border-l py-2 px-4 text-sm font-medium`}
                          aria-current={
                            currentTab === index ? 'page' : undefined
                          }
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
              <div className="flex w-full border border-rose-500 bg-gray-50 p-5 min-h-36 rounded-b-md rounded-tr-md mt-[0.px]">
                {tabsData[currentTab]?.text}
                {showSuggestionLoader ? (
                  <div className="flex w-full items-center justify-center ">
                    <Loader />
                  </div>
                ) : (
                  suggestion
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <p className="mb-2 text-[24px] font-bold">Explore</p>
            <div className="flex items-center relative">
              <input
                className="w-full max-w-sm pl-8 pr-2 py-2 bg-teal-50 rounded-2xl border border-teal-300 focus:border-[1px] focus:border-teal-500 focus:outline-none focus:ring-0"
                placeholder={'Search'}
                type="search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && !containsOnlySpaces(searchText)) {
                    // User pressed Enter key
                    handleSearch();
                  } else if (
                    e.key === 'Enter' &&
                    containsOnlySpaces(searchText)
                  ) {
                    toast.error("Search field can't be empty!");
                  }
                }}
              />
              <MagnifyingGlassIcon className="absolute top-[11px] left-2 text-gray-400 w-5 h-5" />
              {/* <button className="border-none text-blue-500 ml-8">{'Cancel'}</button> */}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full z-50 gap-4 mt-8">
            {industryPosts?.map((post: any) =>
              post?.media_url ? (
                <div className="relative" key={post?.id}>
                  <img
                    className="w-full md:w-[260px] h-[200px]"
                    src={post?.media_url}
                    alt={'industry post image'}
                  />
                </div>
              ) : (
                ''
              )
            )}
          </div>
          <div className="flex justify-center items-center">
            {showSearchLoader && <Loader />}
          </div>
          <div className="flex w-full max-w-2xl lg:max-w-4xl justify-center items-center mb-20 gap-5">
            <button
              className="flex justify-center items-center  mt-10 px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold mb-5"
              onClick={() => {
                navigate('/todo');
              }}
            >
              {'Back'}
            </button>
            <button
              className="flex w-full max-w-sm justify-center items-center px-0 lg:px-28 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg mt-10 text-center text-white text-lg font-bold mb-5"
              onClick={() => {
                navigate('/todo');
              }}
            >
              {'Next'}
            </button>
          </div>
          <div className="w-full flex flex-col justify-center">
            <img
              className="hidden md:flex absolute w-[220px] h-[180px] lg:w-[330px] lg:h-[300px] -right-10 -rotate-90 bottom-0 -z-10"
              src={YellowCircle}
              alt={'details circles'}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AnalyzeData;
