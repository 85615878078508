import { SetStateAction, useState, Dispatch } from 'react';
import { toast } from 'sonner';
import React from 'react';
import { OptionType } from '../types';
interface AddIndustryProps {
  setIndustryDropDownData: Dispatch<SetStateAction<OptionType[]>>;
}
const AddIndustry: React.FC<AddIndustryProps> = ({
  setIndustryDropDownData
}) => {
  const [industry, setIndustry] = useState('');
  return (
    <>
      <input
        placeholder="industry"
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setIndustry(e.target.value);
        }}
      ></input>
      <button
        onClick={async () => {
          const url = `https://instagram-data1.p.rapidapi.com/user/feed/v2?username=${industry}`;
          const options = {
            method: 'GET',
            headers: {
              // 'x-rapidapi-key': 'c2e86b0460mshe1146136755bf0ep1b3b42jsnbfb1f7d5b9f9',
              'x-rapidapi-key':
                'c2e86b0460mshe1146136755bf0ep1b3b42jsnbfb1f7d5b9f9',
              'x-rapidapi-host': 'instagram-data1.p.rapidapi.co'
            }
          };

          try {
            const response = await fetch(url, options);
            const result = await response.json();

            if (result) {
              //store industry names to be fetched for dropdown
              const industryNames = localStorage.getItem('IndustryNames');
              var industryArray = industryNames
                ? JSON.parse(industryNames)
                : [];
              industryArray?.push({ name: industry });
              localStorage.setItem(
                'IndustryNames',
                JSON.stringify(industryArray)
              );
              // set industry data
              const industryData = localStorage.getItem('IndustryData') || '';
              const industryDataArray =
                industryData.length > 0 ? JSON.parse(industryData) : [];
              industryDataArray.push({
                industry: industry,
                data: result.items
              });
              localStorage.setItem(
                'IndustryData',
                JSON.stringify(industryDataArray)
              );
              setIndustryDropDownData((prev: any) => [
                ...prev,
                { value: industry, label: industry }
              ]);
              toast.info('Industry Added.');
            } else {
              toast.error('Industry Not Found!');
            }
          } catch (error) {
            console.error(error);
          }
        }}
      >
        Add
      </button>
    </>
  );
};

export default AddIndustry;
