'use client';
import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import React from 'react';

import SignUpCircles from '../../../../assets/Sign-In-Circles.png';
// import ClueLogo from '../../../../assets//Clue-Logo.png';
import CalendarIcon from '../../../../assets//Calender-Icon.png';
import DaysLoader from './component/days-loader';
// import SideNavBar from '../sidebar-nav/index';
import { setAwarenessDays } from '../../../../redux/slices/day.awareness.slice';
interface PlanInterface {
  id: number;
  name: string;
  date: string;
  image: string;
}
const AwarenessDayDetails = () => {
  const dispatch = useDispatch();
  // const days = useSelector((state: any) => state.)
  const [selectedPlans, setSelectedPlans] = useState<PlanInterface[]>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [dropdownData, setDropdownData] = useState({
    relevantPlan: [
      {
        id: 1,
        image:
          'https://www.awarenessdays.com/wp-content/uploads/2023/10/iStock-638814166-1.jpg',
        name: 'Industry wide1',
        date: ''
      },
      {
        id: 2,
        image:
          'https://www.awarenessdays.com/wp-content/uploads/2023/10/iStock-638814166-1.jpg',
        name: 'Industry wide2',
        date: ''
      },
      {
        id: 3,
        image:
          'https://www.awarenessdays.com/wp-content/uploads/2023/10/iStock-638814166-1.jpg',
        name: 'UK wide1',
        date: ''
      },
      {
        id: 4,
        image:
          'https://www.awarenessdays.com/wp-content/uploads/2023/10/iStock-638814166-1.jpg',
        name: 'UK wide2',
        date: ''
      }
    ]
  });
  const { state } = useLocation();
  const navigate = useNavigate();
  const planId = localStorage.getItem('PlanId');

  const inputPlanDetails = () => {
    const bodyData = {
      planId: planId,
      inputPlan: {
        selectedPlans: selectedPlans
      },
      lastStep: 'input-plan'
    };
    console.log('first', bodyData);

    fetch(`${process.env.REACT_APP_BACKEND_URI}/plan/update`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })
      .then((res) => {
        console.log('User Add Response', res);
        if (res.ok) {
          if (state?.edit) {
            navigate('/review-my-content');
          } else navigate('/asking-questions');
        } else {
          toast.error('Please Provide Complete Info:');
        }
      })
      .catch((err) => {
        console.log('Error adding user details!', err);
      });
  };
  const onPlanClick = (plan: PlanInterface) => {
    // Check if the plan is already selected
    if ((selectedPlans as PlanInterface[]).includes(plan)) {
      // If selected, remove it from the selected plans
      setSelectedPlans(
        selectedPlans.filter((selectedPlan) => plan.id !== selectedPlan.id)
      );
    } else {
      // If not selected, add it to the selected plans
      setSelectedPlans([...selectedPlans, plan]);
    }
    console.log('selectedPlans', selectedPlans);
  };
  useEffect(() => {
    if (!state?.edit) {
      console.log('fetching days of awareness for plan id', planId);
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/scrape-days-of-awareness/${planId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          console.log('Scrape days of awareness response', res);
          if (res.ok) {
            const response = await res.json();
            setDropdownData((previous) => ({
              ...previous,
              relevantPlan: response.daysOfAwareness
            }));
            setShowLoader(false);
            dispatch(setAwarenessDays({ days: response.daysOfAwareness }));
          } else {
            toast.error('Please Provide Complete Info:');
          }
        })
        .catch((err) => {
          console.log('Error fetching days of awareness', err);
        });
    }
    // fetch selected days of awareness
    else if (state?.edit) {
      console.log(state.awarenessDays);
      setDropdownData({ relevantPlan: state.awarenessDays });
      setShowLoader(false);

      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/fetch-selected-days-of-awareness/${planId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          if (res.ok) {
            const response = await res.json();
            console.log('fetch selected*', response.selectedAwarenessDays);

            setSelectedPlans(response?.selectedAwarenessDays);
          } else {
            toast.error('Please Provide Complete Info:');
          }
        })
        .catch((err) => {
          console.log('Error fetching days of awareness', err);
        });
    }
  }, []);

  return (
    <div className="w-full flex flex-col justify-center">
      {/* <SideNavBar /> */}
      <div
        className={`lg:flex h-full md:h-screen ${
          showLoader ? 'lg:h-screen' : 'lg:h-full'
        } w-full relative lg:pb-10`}
      >
        <div className="flex flex-col w-full items-center">
          <div className="flex flex-col items-center w-full mt-10">
            <p className="text-black text-4xl font-medium ">{'Add Details'}</p>
            <p className="flex justify-center items-center text-center text-black text-5xl font-extrabold mb-8">
              {'Input plan [....]?'}
            </p>
            <div className="w-full max-w-md px-5 md:px-0 ">
              <div className="w-full  flex-col md:flex-row justify-center md:justify-between py-5 md:py-0 h-fit md:h-[150px] rounded-2xl border border-cyan-500 items-center px-5 inline-flex mb-5 space-y-2 md:space-y-0">
                <img
                  className="w-[70px] md:w-[96px] h-[70px] md:h-[96px] mr-2"
                  src={CalendarIcon}
                  alt={'details circles'}
                />
                <p className="text-center text-black text-2xl font-semibold ">
                  {'Days of Awareness'}
                </p>
                <div className="flex justify-center items-center space-x-2"></div>
              </div>
              <div className="border border-gray-200 rounded-lg">
                {showLoader ? (
                  <>
                    <DaysLoader screen="awareness-days" />
                  </>
                ) : (
                  <div className="flex flex-col gap-2">
                    {dropdownData.relevantPlan.map((plan) => (
                      <div
                        className={`flex items-center p-3 cursor-pointer space-x-10  ${
                          selectedPlans.some(
                            (selectedPlan) => selectedPlan.id === plan.id
                          )
                            ? 'bg-cyan-50 border border-cyan-500 rounded-lg'
                            : 'border border-gray-300 rounded-lg'
                        }`}
                        key={plan.id}
                        onClick={() => onPlanClick(plan)}
                      >
                        <div className=" flex w-fit space-x-3 items-center">
                          <div
                            className={` ${
                              selectedPlans.some(
                                (selectedPlan) => selectedPlan.id === plan.id
                              )
                                ? 'max-w-[10px] w-full h-[10px] bg-cyan-500 rounded-full'
                                : 'max-w-[10px] w-full h-[10px] bg-gray-400 rounded-full'
                            }`}
                          ></div>
                          <div>
                            <img src={plan.image} className="w-20 h-16 "></img>
                          </div>
                        </div>
                        <div className="flex flex-col">
                          <p
                            className="text-base max-w-[240px] w-full  text-start "
                            title={plan.name}
                          >
                            {plan.name}
                          </p>
                          <p
                            className="text-base max-w-[140px] w-full  text-start"
                            title={plan.date}
                          >
                            {plan.date}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex gap-5 w-full">
                <button
                  className="flex  justify-center items-center px-0 lg:px-[60px] py-3 bg-white border-2 border-rose-500  rounded-lg mt-10 text-center text-rose-500 text-lg font-bold  mb-5"
                  onClick={() => {
                    navigate('/calendar');
                  }}
                >
                  {'Back'}
                </button>
                <button
                  className="flex  justify-center items-center px-0 lg:px-28 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg mt-10 text-center text-white text-lg font-bold  mb-5 "
                  onClick={() => {
                    inputPlanDetails();
                  }}
                >
                  {'Next'}
                </button>
              </div>
            </div>
          </div>
        </div>
        <img
          className="hidden md:flex absolute w-[400px] h-[330px] lg:w-[443px] lg:h-[443px] right-0 bottom-0 -z-10 -rotate-90"
          src={SignUpCircles}
          alt={'details circles'}
        />
        {/* <div className="md:absolute bottom-2 right-10 justify-center md:justify-end items-center gap-4 mb-5 md:mb-0 inline-flex w-full">
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div> */}
      </div>
    </div>
  );
};
export default AwarenessDayDetails;
