import React, { useState, useEffect } from 'react';
import { toast } from 'sonner';
import DaysLoader from '../../day-of-awareness/component/days-loader';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
interface PlanInterface {
  id: number;
  name: string;
  date: string;
  image: string;
}
interface Props {
  planId: string;
}
const InputtedPlans: React.FC<Props> = ({ planId }) => {
  const days = useSelector((state: any) => state.days);
  console.log('InputtedPlans', planId);
  const planIdString = planId;

  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(true);
  const [selectedAwarenessDays, setSelectedAwarenessDays] = useState<
    PlanInterface[]
  >([]);
  const [dropdownData, setDropdownData] = useState({
    relevantPlan: [
      {
        id: 1,
        image:
          'https://www.awarenessdays.com/wp-content/uploads/2023/10/iStock-638814166-1.jpg',
        name: 'Industry wide1',
        date: ''
      },
      {
        id: 2,
        image:
          'https://www.awarenessdays.com/wp-content/uploads/2023/10/iStock-638814166-1.jpg',
        name: 'Industry wide2',
        date: ''
      },
      {
        id: 3,
        image:
          'https://www.awarenessdays.com/wp-content/uploads/2023/10/iStock-638814166-1.jpg',
        name: 'UK wide1',
        date: ''
      },
      {
        id: 4,
        image:
          'https://www.awarenessdays.com/wp-content/uploads/2023/10/iStock-638814166-1.jpg',
        name: 'UK wide2',
        date: ''
      }
    ]
    // planDates: [
    //   { id: 1, name: 'Revenue generating day' },
    //   { id: 2, name: 'Key business day' }
    // ]
  });
  useEffect(() => {
    console.log('fetching days of awareness for plan id', planIdString);
    // if awareness days exists in redux show them
    if (days?.awarenessDays[0]?.name !== '') {
      console.log('###', days);
      setDropdownData({ relevantPlan: days.awarenessDays });
      setShowLoader(false);
    }
    // else if days not exist in redux then fetch from backend
    else {
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/scrape-days-of-awareness/${planIdString}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          console.log('Scrape days of awareness response', res);
          if (res.ok) {
            const response = await res.json();
            setDropdownData((previous) => ({
              ...previous,
              relevantPlan: response.daysOfAwareness
            }));
            setShowLoader(false);
          } else {
            toast.error('Error fetching days of awareness');
          }
        })
        .catch((err) => {
          console.log('Error fetching days of awareness', err);
        });
    }
  }, []);
  useEffect(() => {
    // fetch selected days of awareness
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/plan/fetch-selected-days-of-awareness/${planIdString}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        if (res.ok) {
          const response = await res.json();
          console.log('fetch selected*', response.selectedAwarenessDays);
          setSelectedAwarenessDays(response?.selectedAwarenessDays);
        } else {
          toast.error('Please Provide Complete Info:');
        }
      })
      .catch((err) => {
        console.log('Error fetching days of awareness', err);
      });
  }, []);
  return (
    <div className="w-full mt-20 px-5 md:px-0">
      <div className="w-full p-5 bg-white shadow-lg">
        <div className="w-full flex justify-between items-center mb-10">
          <p className="text-xl font-bold">Inputted Plans</p>
          <button
            className="w-16 h-10 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 text-center text-neutral-800 text-sm font-medium"
            onClick={() => {
              navigate('/day-awareness', {
                state: { edit: true, awarenessDays: dropdownData.relevantPlan }
              });
            }}
          >
            Edit
          </button>
        </div>
        <div className="w-full">
          <label>Days of Awareness</label>
        </div>
        <div className="border border-gray-200 rounded-lg">
          {showLoader ? (
            <>
              <DaysLoader />
            </>
          ) : (
            <div className="flex flex-col gap-2">
              {dropdownData.relevantPlan.map((plan, index) => (
                <div
                  className={`flex items-center p-3 cursor-pointer space-x-10  ${
                    selectedAwarenessDays?.some(
                      (selectedPlan) => selectedPlan.id === plan.id
                    )
                      ? 'bg-cyan-50 border border-cyan-500 rounded-lg'
                      : 'border border-gray-300 rounded-lg'
                  }`}
                  key={index}
                  // onClick={() => onPlanClick(plan)}
                >
                  <div className=" flex w-fit space-x-3 items-center">
                    <div
                      className={` ${
                        selectedAwarenessDays?.some(
                          (selectedPlan) => selectedPlan.id === plan.id
                        )
                          ? 'max-w-[10px] w-full h-[10px] bg-cyan-500 rounded-full'
                          : 'max-w-[10px] w-full h-[10px] bg-gray-400 rounded-full'
                      }`}
                    ></div>
                    <div>
                      <img src={plan.image} className="w-20 h-16 "></img>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p
                      className="text-base max-w-[240px] w-full  text-start "
                      title={plan.name}
                    >
                      {plan.name}
                    </p>
                    <p
                      className="text-base max-w-[140px] w-full  text-start"
                      title={plan.date}
                    >
                      {plan.date}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="flex w-full justify-center items-center gap-5">
        <button
          className="flex justify-center items-center mt-10 my-8  px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold"
          onClick={() => {
            navigate(-1);
          }}
        >
          {'Back'}
        </button>
      </div>
    </div>
  );
};
export default InputtedPlans;
