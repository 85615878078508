import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate, useLocation } from 'react-router-dom';
import React from 'react';
import GoogleLogo from '../../../assets/Google-Logo.png';

const GoogleLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // google login implementation and data fetched from google api
  const googleLogin = useGoogleLogin({
    onSuccess: (tokenResponse: any) => {
      console.log({ tokenResponse });
      fetch(
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${tokenResponse.access_token}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${tokenResponse.access_token}`,
            Accept: 'application/json'
          }
        }
      )
        .then((resp) => resp.json())
        .then((profileData: any) => {
          if (profileData.id) {
            // setProfile(profileData);
            console.log('Profile Data :', profileData);
            googleLoginBackend(profileData);
          }
        })
        .catch((error) => {
          console.log('Error Occurred Fetching Data From Google', error);
        });
      localStorage.setItem('LoginToken', tokenResponse.access_token);
    },
    onError: (error) => console.log('Login Failed:', error)
  });
  //login using fetched profile from google
  const googleLoginBackend = (profileData: any) => {
    const profile = {
      fullname: profileData.name,
      email: profileData.email,
      picture: profileData.picture
    };
    try {
      fetch(`${process.env.REACT_APP_BACKEND_URI}/auth/google-login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          // Add any other headers as needed
        },
        body: JSON.stringify(profile)
      })
        .then((response) => response.json())
        .then((response) => {
          const responseData = response.data.response;
          localStorage.setItem('UserEmail', profile.email);
          if (responseData.isSignup) {
            fetch(
              `${process.env.REACT_APP_BACKEND_URI}/user/check-if-user-details-added/${profile.email}`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
              .then(async (res) => {
                console.log('User Add Response', res);
                if (res.ok) {
                  const response = await res.json();
                  console.log(response.userDetailAdded);
                  if (response?.userDetailAdded) {
                    navigate('/createplan');
                  } else {
                    navigate('/connect-instagram');
                  }
                }
              })
              .catch((err) => {
                console.log('Error adding user details!', err);
              });
          } else {
            fetch(
              `${process.env.REACT_APP_BACKEND_URI}/user/check-if-user-details-added/${profile.email}`,
              {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
              .then(async (res) => {
                console.log('User Add Response', res);
                if (res.ok) {
                  const response = await res.json();
                  console.log(response.userDetailAdded);
                  if (response?.userDetailAdded) {
                    navigate('/todo');
                  } else {
                    navigate('/user-details');
                  }
                }
              })
              .catch((err) => {
                console.log('Error adding user details!', err);
              });
          }
        })
        .catch((error) => {
          console.log('Error loging with google', error);
        });
    } catch (error) {
      console.log('Error loging in google', error);
    }
  };
  return (
    <button
      className="relative flex justify-center items-center w-full h-12 border border-[#01C3C3] rounded-lg mb-4 hover:bg-cyan-50"
      onClick={() => googleLogin()}
    >
      <img
        className="absolute top-[11px] left-5 md:left-10 w-6 h-6"
        src={GoogleLogo}
        alt="Google Logo"
      />
      <span>
        Sign {location.pathname === '/login' ? 'in' : 'up'} with Google
      </span>
    </button>
  );
};

export default GoogleLogin;
