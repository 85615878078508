import { useState } from 'react';
import React from 'react';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import FunIcon from '../../../../../src/assets/fun-popup/Fun.png';
import videoBackground from '../../../../assets/play-video/video_background.png';
import playBtn from '../../../../assets/play-video/play_btn.png';
import YellowCircle from '../../../../assets/Yellow-Circle-Left.png';
import CustomSelect from '../add-details/component/CustomSelect';
import FunPopup from './fun-popup';

const PlayVideo = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isVideoHelpful, setVideoHelpful] = useState(false);
  const dropdownData = [
    { id: 1, name: 'Yes' },
    { id: 2, name: 'No' }
  ];

  const navigate = useNavigate();

  const planId = localStorage.getItem('PlanId');
  const videoHelpful = async () => {
    const bodyData = {
      planId: planId,
      isVideoHelpful: isVideoHelpful,
      lastStep: 'play-video'
    };
    console.log({ bodyData });
    if (!isVideoHelpful) {
      toast.error('Please provide complete details');
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BACKEND_URI}/plan/update`,
          {
            method: 'PATCH',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(bodyData)
          }
        );
        if (response.ok) {
          const data = await response.json();
          console.log({ data });

          if (data?.code === 200) {
            navigate('/asking-questions');
          } else {
            throw new Error(data.status);
          }
        } else {
          throw new Error('Unable to connect to Backend');
        }
      } catch (error: any) {
        toast.error(`${error?.message}`);
        console.log(error.message);
        console.log(error.msg);

        // Handle network errors or exceptions
        console.error('Error:', error);
      }
    }
  };
  const openPopup = (e: MouseEvent) => {
    console.log('opn popup called');
    e.stopPropagation();
    setPopupVisible(true);
  };

  const closePopup = () => {
    console.log('Close popup called');
    setPopupVisible(false);
  };
  return (
    <div className="w-full flex flex-col justify-center">
      <div className="lg:flex h-screen w-full relative lg:pb-10 px-5 md:px-0">
        <div className="flex flex-col w-full items-center ">
          <div className="flex flex-col items-center w-full mt-10"></div>
          {isPopupVisible && (
            <FunPopup
              onClose={closePopup}
              icon={FunIcon}
              description="This is the fun part! Go get yourself a tea and some biscuits or if
              you’re doing this past 8pm maybe a cheeky G&T"
            />
          )}
          <div className="relative">
            <img src={videoBackground} alt="Video Background"></img>
            <img
              className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-12 h-12 bg-transparent border-none cursor-pointer"
              src={playBtn}
              onClick={(e: any) => {
                openPopup(e);
              }}
              alt="Play Icon"
            ></img>
          </div>
          <div className="mt-5 w-full max-w-md">
            <CustomSelect
              data={dropdownData.map((item) => ({
                id: item.id,
                name: item.name
              }))}
              placeholder={'Is video is helpful for you?'}
              onSelect={(selectedItem: any) => {
                setVideoHelpful(selectedItem.name);
              }}
            />
            <div className="flex w-full justify-center items-center gap-5">
              <button
                className="flex justify-center items-center  my-8  px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {'Back'}
              </button>
              <button
                className="flex w-full justify-center items-center px-0 lg:px-28 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg mt-4 text-center text-white text-lg font-bold  mb-5"
                onClick={() => {
                  videoHelpful();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <img
          className="hidden md:flex absolute w-[400px] h-[330px] lg:w-[443px] lg:h-[393px] right-0 -rotate-90 bottom-0 -z-10"
          src={YellowCircle}
          alt={'details circles'}
        />
        {/* <div className="md:absolute bottom-2 right-10 justify-center md:justify-end items-center gap-4 my-5 md:my-0 inline-flex w-full">
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div> */}
      </div>
    </div>
  );
};
export default PlayVideo;
