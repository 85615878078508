import { useNavigate } from 'react-router-dom';
import awarenessPopupIcon from '../../../../../assets/awareness-screen-popup/Exclamation.png';
const AwarenessScreenPopupIcon = () => {
  const navigate = useNavigate();
  return (
    <div className=" flex flex-col max-w-[350px] md:max-w-md  px-12 py-5 rounded-lg gap-4 absolute bg-slate-50 z-50 justify-center items-center top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/4 shadow-xl  border border-cyan-500 cursor-pointer">
      <div className="flex flex-col items-center justify-center mt-5">
        <img
          className="w-16 h-16 mb-3"
          src={awarenessPopupIcon}
          alt="awarenessPopupIcon"
        ></img>
        <p className="text-center text-xl font-semibold ">
          Want to add in any days of awareness into your content plan?
        </p>
      </div>
      <div className="flex justify-between gap-10">
        <button
          className="font-semibold text-rose-500 bg-white border border-rose-500 text-center px-8 py-2 shadow-xl rounded-md"
          onClick={() => {
            navigate('/play-video');
          }}
        >
          Skip
        </button>
        <button
          className="font-semibold shadow-xl bg-rose-500 text-white px-8 py-2 text-center rounded-md border"
          onClick={() => {
            navigate('/day-awareness');
          }}
        >
          Yes
        </button>
      </div>
    </div>
  );
};

export default AwarenessScreenPopupIcon;
