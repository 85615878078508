import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import RedCircle from '../../../../assets/Red_Circle_Left.png';
import ClueLogo from '../../../../assets//Clue-Logo.png';
import NewPlan from '../../../../assets//Create-Plan.png';
import ViewContent from '../../../../assets//View-Content.png';
import LeftOff from '../../../../assets//Left-Off.png';
import MyData from '../../../../assets//My-Data.png';
import { updatePlanIDInDb } from '../create-plan/component/UpdatePlanIDInDB';
const ToDo = () => {
  const navigate = useNavigate();
  const planId = localStorage.getItem('PlanId');
  const createPlan = async () => {
    const userEmail = localStorage.getItem('UserEmail');
    console.log('creating plan for', userEmail);
    const bodyData = {
      email: userEmail,
      lastStep: 'create-plan'
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/create`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log({ data });

        if (data?.code == 200) {
          navigate('/createplan');
          localStorage.setItem('PlanId', data.planId);
          updatePlanIDInDb(data.planId, userEmail || '');
        } else {
          throw new Error(data.status);
        }
      } else {
        throw new Error('Unable to connect to Backend');
      }
    } catch (error: any) {
      toast.error(`${error?.message}`);
      console.log(error.message);
      console.log(error.msg);

      // Handle network errors or exceptions
      console.error('Error:', error);
    }
  };
  // fetch last step complete to redirect the user
  const whereILeft = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/last-step/${planId}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      if (response.ok) {
        const data = await response.json();
        redirectUserToLastStep(data.lastStep);
      } else {
        throw new Error('Unable to connect to Backend');
      }
    } catch (error: any) {
      toast.error(`${error?.message}`);
      console.log(error.message);
      console.log(error.msg);

      // Handle network errors or exceptions
      console.error('Error:', error);
    }
  };
  const redirectUserToLastStep = (lastStep: string) => {
    if (lastStep === 'create-plan') navigate('/createplan');
    else if (lastStep === 'select-plan-type') navigate('/calendar');
    else if (lastStep === 'input-strategic-date') navigate('/day-awareness');
    else if (lastStep === 'input-plan') navigate('/asking-questions');
    else if (lastStep === 'asking-questions')
      navigate('/weekly-content-strategy');
    else if (lastStep === 'weekly-content-strategy')
      navigate('/content-development');
    else if (lastStep === 'content-development') navigate('/content-plan');
    else if (lastStep === 'content-plan') navigate('/review-my-content');
  };
  return (
    <div className="w-full flex flex-col h-full md:h-screen justify-center relative">
      <div className="flex w-full">
        <div className="flex flex-col w-full items-center px-10 md:px-24">
          <div className="flex flex-col items-center w-full mt-10">
            <img
              className="max-w-[225px] max-h-[82px] mb-[40px]"
              src={ClueLogo}
              alt={'Clue Logo'}
            />
            <p className="flex justify-center items-center text-center text-black text-4xl lg:text-5xl font-extrabold  mb-8">
              {'What do you want to do today?'}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-5 mb-5">
              <button
                className="px-5 py-6 bg-white rounded-2xl border border-cyan-500 flex-col items-center inline-flex hover:bg-cyan-50"
                onClick={() => {
                  createPlan();
                }}
              >
                <div className="flex flex-col justify-center items-center text-center">
                  <p className="text-blue-950 text-[26px] font-semibold mb-8 flex justify-center">
                    {'Create '}
                    <span className="ml-2 text-rose-500 text-[26px] font-semibold font-['Public Sans']">
                      {'New Plan'}
                    </span>
                  </p>
                  <img
                    className="max-w-[86px] max-h-[92px]"
                    src={NewPlan}
                    alt={'Clue Logo'}
                  />
                </div>
              </button>
              <button
                className="px-5 py-6 bg-white rounded-2xl border border-cyan-500 flex-col items-center inline-flex hover:bg-cyan-50"
                onClick={() => {
                  whereILeft();
                }}
              >
                <div className="flex flex-col justify-center items-center text-center">
                  <p className="text-blue-950 text-[26px] font-semibold mb-8 flex justify-center">
                    {'Where I  '}
                    <span className="ml-2 text-cyan-500 text-[26px] font-semibold font-['Public Sans']">
                      {'Left Off'}
                    </span>
                  </p>
                  <img
                    className="max-w-[86px] max-h-[92px]"
                    src={LeftOff}
                    alt={'Clue Logo'}
                  />
                </div>
              </button>
              <button
                className="px-5 py-6 bg-white rounded-2xl border border-cyan-500 flex-col items-center inline-flex hover:bg-cyan-50"
                onClick={() => {
                  navigate('/weekly-content-strategy');
                }}
              >
                <div className="flex flex-col justify-center items-center text-center">
                  <p className="text-blue-950 text-[26px] font-semibold mb-8 flex justify-center">
                    {'View '}
                    <span className="ml-2 text-cyan-500 text-[26px] font-semibold font-['Public Sans']">
                      {'Content Plan'}
                    </span>
                  </p>
                  <img
                    className="max-w-[86px] max-h-[92px]"
                    src={ViewContent}
                    alt={'Clue Logo'}
                  />
                </div>
              </button>
              <button
                className="px-5 py-6 bg-white rounded-2xl border border-cyan-500 flex-col items-center inline-flex mb-10 md:mb-0 hover:bg-cyan-50"
                onClick={() => {
                  navigate('/analyze-my-data');
                }}
              >
                <div className="flex flex-col justify-center items-center text-center">
                  <p className="text-blue-950 text-[26px] font-semibold mb-8 flex justify-center">
                    {'Check '}
                    <span className="ml-2 text-cyan-500 text-[26px] font-semibold font-['Public Sans']">
                      {'My Data'}
                    </span>
                  </p>
                  <img
                    className="max-w-[86px] max-h-[92px]"
                    src={MyData}
                    alt={'Clue Logo'}
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
        <img
          className="hidden md:flex absolute w-[443px] h-[393px] lg:w-[300px] lg:h-[300px] right-0 -rotate-90 bottom-0 -z-10"
          src={RedCircle}
          alt={'details circles'}
        />
      </div>
      {/* <div className="absolute bottom-2 lg:right-10 flex w-full justify-center md:justify-end mt-5 items-center gap-4">
        <button className="text-blue-950 text-base font-medium capitalize leading-snug">
          Terms and Conditions
        </button>
        <div className="w-px h-[31px] relative bg-pink-400" />
        <button className="text-blue-950 text-base font-medium capitalize leading-snug">
          Privacy Policy
        </button>
      </div> */}
    </div>
  );
};

export default ToDo;
