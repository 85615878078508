import React, { useEffect } from 'react';
import GreenCircles from '../../../assets/Green-Circle-Right.png';
import SuccessEmailIcon from '../../../assets/SVG/email-success-icon.svg';
import { useNavigate } from 'react-router-dom';
const EmailSuccess = () => {
  const navigate = useNavigate();
  // redirect user to home(add user details) screen
  useEffect(() => {
    setTimeout(() => {
      navigate('/connect-instagram');
      localStorage.setItem('LoginToken', 'test');
    }, 5000);
  });
  return (
    <div className="relative max-w-screen h-screen  bg-white flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center z-10">
        <div className="bg-[#E6F1F1] rounded-[20px] border border-cyan-500 flex flex-col justify-center items-center w-full h-fit px-5 py-5 md:py-0 md:w-[707px] md:h-[400px] ">
          <img
            className="max-w-[150px] max-h-[90px]"
            src={SuccessEmailIcon}
            alt={'no one'}
          />
          <p className="text-black text-[30px] md:text-[40px] text-center font-black">
            Your email Verified <br /> Successfully!
          </p>
        </div>
      </div>
      <img
        className="absolute bottom-0 right-0 w-[110rem] "
        src={GreenCircles}
        alt={'no one'}
      />
    </div>
  );
};

export default EmailSuccess;
