export const getEngagementRate = (data: []) => {
  try {
    const engagmentRates: any = [];
    data.map((post: any, index) => {
      const engagementRate = (post.comment_count / post.like_count) * 100;

      engagmentRates.push(
        // {
        // postId: post.id,

        // engagementRate:
        Math.ceil(engagementRate)
        // }
      );
    });
    return engagmentRates;
  } catch (error) {
    console.log('Error generating engagement rate', error);
  }
};
