import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import RedCircles from '../../../assets/Red-Circle-Right.png';
import EmailIcon from '../../../assets/SVG/email-icon.svg';
import { clearUser } from '../../../redux/slices/user.slice';
const VerifyEmail = () => {
  const [resendEmail, setResendEmail] = useState(true);
  const { user }: any = useSelector((state: any) => state.user);
  const dispatch = useDispatch();
  const resendVerificationMail = async () => {
    console.log(user.email);
    setResendEmail(false);
    await fetch(
      `${process.env.REACT_APP_BACKEND_URI}/mail/resend-verification-mail/${user.email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
          // Add any other headers as needed
        }
      }
    );
  };
  const navigate = useNavigate();
  const handleLogout = () => {
    console.log('logging out');
    dispatch(clearUser(undefined));
    localStorage.removeItem('LoginToken');
    localStorage.removeItem('InstagramAccessToken');
    localStorage.removeItem('InstagramUsername');
    navigate('/login');
  };
  return (
    <div className="relative max-w-screen h-screen  bg-white flex flex-col justify-center items-center">
      <div className="flex flex-col justify-center items-center z-10 px-5">
        <p className="text-black text-3xl md:text-5xl font-black mb-6">
          {'Create your account'}
        </p>
        <div className="bg-[#E6F1F1] rounded-[20px] border border-cyan-500 flex flex-col justify-center items-center w-full h-fit px-5 py-8 md:py-0 md:w-[707px] md:h-[400px]  ">
          <img
            className="max-w-[150px] max-h-[90px]"
            src={EmailIcon}
            alt={'no one'}
          />
          <p className="text-black text-center leading-tight text-[30px] md:text-[40px] font-extrabold mb-2 md:mb-0">
            {'Verify your email'}
          </p>
          <p className="text-center text-black text-base font-semibold">
            We've sent you an email to verify your email <br />
            address and finish creating your account.
          </p>
          <p className="text-black text-2xl font-bold  mt-20">
            {'Email not received?'}
          </p>
          <div
            className={
              resendEmail
                ? 'opacity-80 text-center text-cyan-500 text-base font-bold underline capitalize cursor-pointer'
                : 'opacity-80 text-center text-slate-600 text-base font-bold underline capitalize '
            }
            onClick={() => {
              resendVerificationMail();
            }}
          >
            {'Resend Email'}
          </div>
        </div>
        <button
          className="w-1/3 h-10 bg-cyan-500 text-white mt-5 font-medium"
          onClick={() => {
            handleLogout();
          }}
        >
          Log out
        </button>
      </div>
      <img
        className="absolute bottom-0 right-0 w-[85rem]"
        src={RedCircles}
        alt={'no one'}
      />
    </div>
  );
};

export default VerifyEmail;
