import React from 'react';
import { useNavigate } from 'react-router-dom';
import YellowCircle from '../../../../../src/assets/Yellow-Circle-Left.png';
import CyanCalendar from '../../../../../src/assets/SVG/Plan-Calendar-Cyan.svg';
import RedCalendar from '../../../../../src/assets/SVG/Plan-Calendar-Red.svg';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
const ContentPlan = () => {
  const navigate = useNavigate();
  const planId = localStorage.getItem('PlanId');
  console.log({ planId });
  const viewContentPlan = async () => {
    const bodyData = {
      planId: planId,

      lastStep: 'content-plan'
    };
    console.log({ bodyData });
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/update`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        }
      );
      if (response.ok) {
        const data = await response.json();
        console.log({ data });

        if (data?.code === 200) {
          navigate('/content-development');
        } else {
          throw new Error(data.status);
        }
      } else {
        throw new Error('Unable to connect to Backend');
      }
    } catch (error: any) {
      console.log(error.message);
      console.log(error.msg);
      // Handle network errors or exceptions
      console.error('Error:', error);
    }
  };
  return (
    <div className="flex flex-col h-screen relative lg:pb-10 items-center">
      <div className="flex flex-col items-center w-full mt-10">
        <p className="text-black text-4xl font-medium mb-3">{'Content plan'}</p>
        <p className="flex justify-center items-center text-center text-black text-4xl md:text-5xl font-extrabold mb-14">
          {'View my [....]?'}
        </p>
      </div>
      <div className="flex flex-col w-full max-w-xl justify-between space-y-6 items-center px-5 md:px-0">
        <button
          className="w-full flex justify-between items-center p-5 border border-cyan-500 rounded-lg bg-white"
          onClick={() => {
            navigate('/review-my-content');
          }}
        >
          <div className="flex justify-center items-center">
            <img
              className="flex w-10 h-10 mr-5"
              src={RedCalendar}
              alt={'details circles'}
            />
            <p className="w-full text-neutral-800 text-xl font-semibold">
              {'View My Content Plan'}
            </p>
          </div>
          <ArrowRightIcon className="flex w-[24px] h-[24px] text-red-500" />
        </button>
        <button
          className="w-full flex justify-between items-center p-5 border border-cyan-500 rounded-lg bg-white hover:bg-cyan-50"
          onClick={() => {
            navigate('/createplan');
          }}
        >
          <div className="flex justify-center items-center">
            <img
              className="flex w-10 h-10 mr-5"
              src={CyanCalendar}
              alt={'details circles'}
            />
            <p className="w-full text-neutral-800 text-xl font-semibold">
              {'Create New Plan'}
            </p>
          </div>
          <ArrowRightIcon className="flex w-[24px] h-[24px] text-red-500" />
        </button>
        <div className="flex w-full justify-center items-center gap-5">
          <button
            className="flex justify-center items-center  mt-10 px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold mb-5"
            onClick={() => {
              navigate(-1);
            }}
          >
            {'Back'}
          </button>
          <button
            className="flex w-full justify-center items-center px-0 lg:px-10 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg mt-10 text-center text-white text-lg font-bold mb-5"
            onClick={() => {
              viewContentPlan();
            }}
          >
            {'Next'}
          </button>
        </div>
      </div>
      <div className="w-full flex flex-col justify-center ">
        <img
          className="hidden md:flex absolute w-[400px] h-[380px] lg:w-[380px] lg:h-[330px] right-0 -rotate-90 bottom-0 -z-10"
          src={YellowCircle}
          alt={'details circles'}
        />
        {/* <div className="md:absolute bottom-2 right-10 justify-center md:justify-end items-center gap-4 my-5 md:my-0 inline-flex w-full">
          <button
            className="text-blue-950 text-base font-medium capitalize leading-snug cursor-pointer"
            onClick={() => {
              console.log('i wam clicked');
            }}
          >
            Terms and Conditions
          </button>
          <div className="w-px h-[31px] relative bg-pink-400" />
          <button className="text-blue-950 text-base font-medium capitalize leading-snug">
            Privacy Policy
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ContentPlan;
